import {
  Avatar, Stack, Typography,
} from '@mui/material';
import { BusinessCenterRounded } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import Button from '@mui/material/Button';


type TProps = {
    logo? : string,
    jobTitle: string,
    companyName: string,
    onButtonClick: () => void,
    jobPosted: string,
    jobUrl: string,
}

export default function JobDetailsHeader({
  logo, jobTitle, companyName, onButtonClick, jobPosted, jobUrl,
}: TProps) {
  const handleClick = () => {
    window.open(jobUrl, '_blank');
    onButtonClick();
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ alignItems: 'flex-start' }}>
      <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flex: '1 1 auto' }}>
        {logo ? (
          <Avatar src={logo} sx={{ '--Avatar-size': '64px' }}>
            {jobTitle[0]}
          </Avatar>
        ) : (
          <Avatar>
            <BusinessCenterRounded />
          </Avatar>
        )}
        <div>
          <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography variant='h6' color='text.secondary'>{companyName}</Typography>
            <Chip
              key={jobPosted}
              label={`Posted ${jobPosted}`}
              variant='filled'
              size='small'
              sx={{ fillOpacity: '60%', backgroundColor: '#4CAF5026', color: 'black' }}
            />
          </Stack>
          <Typography variant='h4'>
            {jobTitle}
          </Typography>
        </div>
      </Stack>
      <div>
        <Stack direction='row' spacing={2}>
          <Button
            variant='contained'
            color='primary'
            endIcon={<OpenInNewIcon />}
            onClick={handleClick}
          >
            Apply Now
          </Button>
        </Stack>
      </div>
    </Stack>
  );
}
