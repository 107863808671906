import {
  Card,
  CardContent,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Icon, List,
} from '@mui/material';
import { IJobDetails } from 'pages/JobBoard/queries';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import SourceIcon from '@mui/icons-material/Source';
import React from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


type TProps = {
  jobDetails: IJobDetails;
};

export default function JobDetailsCard({ jobDetails }: TProps) {
  const theme = useTheme();
  return (
    <Card sx={{ boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
      <CardContent>
        <Grid container spacing={2}>
          {/* Qualifications Section */}
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Icon sx={{ color: theme.palette.error.light, width: '32px', height: '32px' }}>
                <TrackChangesIcon fontSize='large' />
              </Icon>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                Qualifications
              </Typography>
            </Stack>
            <Grid item xs={12}>
              <List>
                {jobDetails.qualifications.map((qualification, index) => (
                  <ListItem key={qualification} sx={{ paddingLeft: 0 }}>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant='body1' sx={{ color: 'text.primary' }}>{qualification}</Typography>}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* Responsibilities Section */}
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Icon sx={{ color: theme.palette.info.light, width: '32px', height: '32px' }}>
                <SourceIcon fontSize='large' />
              </Icon>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                Responsibilities
              </Typography>
            </Stack>
            <Grid item xs={12}>
              <List>
                {jobDetails.responsibility.map((responsibility, index) => (
                  <ListItem key={responsibility} sx={{ paddingLeft: 0 }}>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant='body1' sx={{ color: 'text.primary' }}>{responsibility}</Typography>}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}> */}
          {/*  <Divider /> */}
          {/* </Grid> */}
          {/* Benefits Section */}
          {/* <Grid item xs={12}> */}
          {/*  <Stack direction='row' spacing={2} alignItems='center'> */}
          {/*    <Icon sx={{ color: theme.palette.warning.light,
          width: '32px', height: '32px' }}> */}
          {/*      <StarsIcon fontSize='large' /> */}
          {/*    </Icon> */}
          {/*    <Typography variant='h5' sx={{ fontWeight: 'bold' }}> */}
          {/*      Benefits */}
          {/*    </Typography> */}
          {/*  </Stack> */}
          {/*  <Grid item xs={12}> */}
          {/*    <List> */}
          {/*      {jobDetails.benefits.map((benefit, index) => ( */}
          {/*        <ListItem key={benefit} sx={{ paddingLeft: 0 }}> */}
          {/*          <ListItemIcon> */}
          {/*            <FiberManualRecordIcon sx={{ height: '15px' }} /> */}
          {/*          </ListItemIcon> */}
          {/*          <ListItemText */}
          {/*            primary={<Typography variant='body1'
          sx={{ color: 'text.primary' }}>{benefit}</Typography>} */}
          {/*          /> */}
          {/*        </ListItem> */}
          {/*      ))} */}
          {/*    </List> */}
          {/*  </Grid> */}
          {/* </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}
