import React, { useEffect, useState } from 'react';
import {
  Card, Tabs, Tab, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TestCase } from 'pages/HomePage/queries';
import TestEvaluationDetails from 'pages/Questions/components/TestEvaluationDetails';
import DetailedEvaluationScore from 'pages/Questions/components/DetailedEvaluationScore';
import { ISolution, useGetTestList, userTestListKey } from 'pages/Submissions/queries';
import { ERequestStatus } from 'pages/HomePage/types';


const StyledCard = styled(Card)({
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  padding: '16px',
  backgroundColor: '#fff',
});

type TProps = {
    questionId: string;
    testCases: TestCase[];
    submission: ISolution;
};

export default function QuestionEvaluationResults({ questionId, testCases, submission }: TProps) {
  const [activeTab, setActiveTab] = useState(0); // State to manage active tab
  const [polling, setPolling] = useState<boolean>(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const {
    data: testData,
    isLoading: testDataProcessing,
  } = useGetTestList(submission.id || '', {
    queryKey: userTestListKey(submission.id || ''),
    enabled: Boolean(submission.id),
    refetchInterval: polling ? 10000 : false,
  });

  useEffect(() => {
    if (testData && testData.data.length === 0) {
      setPolling(false);
    }
    else if (testData?.data[0].status !== ERequestStatus.PROCESSING) {
      setPolling(false);
    }
  }, [testData]);

  return (
    <StyledCard>
      {/* Tab Navigation */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label='test cases tabs'>
          <Tab label='Test Cases' />
          <Tab label='Repository Score' />
        </Tabs>
      </Box>

      {/* Tab Content */}
      {activeTab === 0 && (
      <TestEvaluationDetails
        pollingFunc={() => setPolling(true)}
        testResults={testData?.data}
        testResultsLoading={testDataProcessing}
        testCases={testCases}
        solutionAvailable
        questionId={questionId}
        solutionId={submission.id}
      />
      )}

      {activeTab === 1 && (
        <DetailedEvaluationScore submission={submission} />
      )}
    </StyledCard>
  );
}
