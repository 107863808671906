import React, { useState } from 'react';
import {
  Button, Card, Grid, useMediaQuery,
} from '@mui/material';
import {
  IApplication,
  useCreateApplication,
  useGetUserApplicationList,
  userApplicationListQueryKey,
} from 'pages/HomePage/queries';
import Spinner from 'components/Common/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import ApplicationModal from 'pages/HomePage/components/ApplicationModal';
import EmptyApplication from 'pages/HomePage/components/EmptyApplication';
import { ERequestStatus, ESource, CreationType } from 'pages/HomePage/types';
import { EEvents } from 'instrumentation/analytics.types';
import { trackEvents } from 'instrumentation/analytics';
import ApplicationErrorPopup from 'pages/HomePage/components/ApplicationErrorPopup';
import Typography from '@mui/material/Typography';
import NewApplicationView from 'pages/HomePage/components/NewApplicationView';
import LoadingCard from 'components/Common/LoadingCard';
import { useTheme } from '@mui/material/styles';
import { differenceInMinutes, parseISO } from 'date-fns';
import { ApplicationFilters, ApplicationFiltersItems } from 'pages/HomePage/components/ApplicationFilters';
import { useLocation } from 'react-router-dom';
import { positionParsed } from 'pages/HomePage/constants';
import stringToDate from 'components/Common/Date';
import EmptyFilteredApplications from 'pages/HomePage/components/EmptyFilteredApplications';
import { homepage } from 'utils/spaUrls';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function HomePage() {
  const theme = useTheme();
  const query = useQuery();
  const errorString = 'URL could not be Parsed';
  const [modalOpen, setModalOpen] = useState(false);
  const createApplicationHook = useCreateApplication();
  const queryClient = useQueryClient();
  const [failedUrls, setFailedUrls] = useState<(string | null)[]>([]);
  const [confirmDisable, setConfirmDisable] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const company = query.get('company') || undefined;
  const position = query.get('position') || undefined;
  const skill = query.get('skill') || undefined;
  const appliedDate = query.get('job_applied') || undefined;

  const {
    data: applicationListData,
    isLoading: applicationListLoading,
  } = useGetUserApplicationList({
    queryKey: userApplicationListQueryKey(),
    refetchInterval: 5000,
  });

  React.useEffect(() => {
    if (applicationListData && applicationListData.data.length > 0) {
      const failedApplications = applicationListData.data
        .filter((app) => app.request_status === ERequestStatus.FAIL)
        .filter((app) => differenceInMinutes(new Date(), parseISO(app.created_at || '')) < 30)
        .filter((failedApp) => failedApp?.error_details
          ?.toLowerCase().includes(errorString.toLowerCase()));
      const failedApplicationUrls = failedApplications.map((app) => app.error_details);
      setFailedUrls(failedApplicationUrls);
    }
  }, [applicationListData]);

  if (!applicationListData || applicationListLoading) {
    return <Spinner />;
  }

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCreateApplication = (value: string, inSource: ESource) => {
    setConfirmDisable(true);
    createApplicationHook.mutate({ url: value, source: inSource }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['users', 'applications', 'list'] });
        setModalOpen(false);
        setConfirmDisable(false);
      },
      onError: () => {
        setConfirmDisable(false);
      },
    });
  };

  const showEmptyState = applicationListData.data.length === 0
      || applicationListData.data.every((app) => app.request_status === ERequestStatus.FAIL);

  const filteredApplications = applyFilters(applicationListData.data, {
    company, position, skill, appliedDate,
  });
  const tabs = [
    { label: 'All', value: '', count: filteredApplications.filter((application) => application.request_status === ERequestStatus.SUCCESS).length },
  ] as const;
  return (
    <Grid container spacing={2} sx={{ flexGrow: 1, padding: 2 }}>
      <Grid item container xs={12} alignItems='flex-end' justifyContent='space-between' sx={{ mb: 2 }}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>Applications</Typography>
        <Button
          size={isMobile ? 'small' : 'medium'}
          variant='contained'
          onClick={() => {
            handleClick();
            trackEvents(EEvents.CLICK, {
              feature: 'HomePage - Application',
              type: 'Button',
              label: 'Track New Application',
            });
          }}
        >
          Track New Application
        </Button>
      </Grid>
      {showEmptyState ? (
        <Grid item xs={12}>
          <EmptyApplication handleClick={handleClick} />
        </Grid>
      ) : (
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <ApplicationFilters
                filters={{
                  company, position, skill, appliedDate,
                }}
                filterTabs={tabs}
                navigateUrl={homepage}
              />
            </Card>
          </Grid>
          {filteredApplications.filter((
            application,
          ) => application.request_status === ERequestStatus.SUCCESS).length === 0 ? (
            <Grid item xs={12}>
              <EmptyFilteredApplications />
            </Grid>
            ) : (
              <Grid item xs={12} container spacing={2}>
                {filteredApplications.map((application) => {
                  if (application.request_status === ERequestStatus.SUCCESS) {
                    return <NewApplicationView key={application.id} application={application} />;
                  }
                  else if (application.request_status === ERequestStatus.FAIL) {
                    return null;
                  }
                  else {
                    return (
                      <LoadingCard status={application.request_status} type={CreationType.APP} />
                    );
                  }
                })}
              </Grid>
            )}
        </Grid>
      )}
      {modalOpen && (
        <ApplicationModal
          isOpen={modalOpen}
          isMobile={isMobile}
          onClose={handleClose}
          title='Track your Application'
          confirm={(url: string, source: ESource) => handleCreateApplication(url, source)}
          disableConfirm={confirmDisable}
        />
      )}
      {failedUrls.length > 0 && <ApplicationErrorPopup errorUrls={failedUrls} />}
    </Grid>
  );
}

function applyFilters(
  row: IApplication[],
  {
    company, position, skill, appliedDate,
  }: ApplicationFiltersItems,
): IApplication[] {
  return row.filter((item) => {
    if (company) {
      if (!item.company?.name.toLowerCase().includes(company.toLowerCase())) {
        return false;
      }
    }

    if (position) {
      const parsedPosition = positionParsed.parseFromTitle(position.toLowerCase());
      if (!item.role || !item.role.toLowerCase().includes(parsedPosition.toLowerCase())) {
        return false;
      }
    }

    if (skill) {
      const lowerCaseSkill = skill.toLowerCase();
      const skillsLowerCase = item.skills && item.skills.map((s) => s.toLowerCase());
      if (skillsLowerCase) {
        if (!skillsLowerCase.includes(lowerCaseSkill)) {
          return false;
        }
      }
    }

    if (appliedDate) {
      const appliedJobDate = stringToDate({ dateString: appliedDate, formatStr: 'MMMM d, yyyy' });
      if (item.job_applied) {
        const jobAppliedDate = stringToDate({ dateString: item.job_applied, formatStr: 'MMMM d, yyyy' });
        if (jobAppliedDate && appliedJobDate) {
          if (jobAppliedDate !== appliedJobDate) {
            return false;
          }
        }
      }
    }

    return true;
  });
}
