import { CardContent, CardHeader, Grid } from '@mui/material';
import CustomizedAccordions from 'pages/HomePage/components/CustomizedAccordion';
import React from 'react';
import { TestCase } from 'pages/HomePage/queries';
import Chip from '@mui/material/Chip';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


type TProps = {
    testCases: TestCase[];
}
const chipLevel = (complexity: string) => {
  const lowerComplexity = complexity ? complexity.toLowerCase() : ''; // Ensure it's not null or undefined
  const colorOrder = ['#4CAF5026', '#FFEB3B26', '#F4433626'];

  switch (lowerComplexity) {
    case 'easy':
      return (
        <Chip
          key='easy'
          label='Easy'
          variant='filled'
          size='small'
          sx={{ opacity: '60%', backgroundColor: colorOrder[0], color: 'black' }}
        />
      );
    case 'medium':
      return (
        <Chip
          key='medium'
          label='Medium'
          variant='filled'
          size='small'
          sx={{ opacity: '60%', backgroundColor: colorOrder[1], color: 'black' }}
        />
      );
    case 'hard':
      return (
        <Chip
          key='hard'
          label='Hard'
          variant='filled'
          size='small'
          sx={{ opacity: '60%', backgroundColor: colorOrder[2], color: 'black' }}
        />
      );
    default:
      return (
        <Chip
          icon={<PlayCircleIcon color='primary' />}
          color='default'
          label='No Complexity Level'
          size='small'
          variant='outlined'
        />
      );
  }
};


export default function TestShowDetails({
  testCases,
}: TProps) {
  return (
    <>
      <CardHeader
        title='Test Cases'
        subheader='Please write unit tests based on the following test cases and include it in your github submission.'
        titleTypographyProps={{ variant: 'h5', fontWeight: 600 }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {testCases.map((test, index) => (
            <Grid item xs={12} key={`test-case-${test.title}`}>
              <CustomizedAccordions
                key={`Test #${index + 1}`}
                content={test.description}
                title={test.title}
                subHeader={`Test #${index + 1}`}
                chip={chipLevel(test.complexity)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </>
  );
}
