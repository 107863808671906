import { CardContent, CardHeader, Grid } from '@mui/material';
import React from 'react';
import { jsonStringParse, ScoreDescriptionCard } from 'pages/Questions/components/QuestionScoreCard';
import { ISolution } from 'pages/Submissions/queries';
import ScoreCircle from 'pages/Questions/components/ScoreCircle';
import CustomizedAccordions from 'pages/HomePage/components/CustomizedAccordion';


type TProps = {
    submission: ISolution;
}

export default function DetailedEvaluationScore({ submission }: TProps) {
  const negativeComments: string[] = jsonStringParse(submission?.negative_comments || '');
  const positiveComments: string[] = jsonStringParse(submission?.positive_comments || '');

  return (
    <>
      <CardHeader
        title='Detailed Repository Score'
        subheader='Here are the detailed results of your submission evaluation.'
        titleTypographyProps={{ variant: 'h5', fontWeight: 600 }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <ScoreCircle score={submission.overall_score} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item>
                <CustomizedAccordions title='Comments' subHeader='General comments regarding entire submission' content='' key='comment 1'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ScoreDescriptionCard title='Strength' comments={positiveComments} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ScoreDescriptionCard title='Weakness' comments={negativeComments} />
                    </Grid>
                  </Grid>
                </CustomizedAccordions>
              </Grid>
              <Grid item>
                <CustomizedAccordions title='File Score' subHeader={"Codei's metric score per file"} content='' key='comment 1'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ScoreDescriptionCard title='Strength' comments={positiveComments} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ScoreDescriptionCard title='Weakness' comments={negativeComments} />
                    </Grid>
                  </Grid>
                </CustomizedAccordions>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}
