import {
  Avatar, Card, CardContent, CardHeader,
  Grid, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import theme from 'theme';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ISolution } from 'pages/Submissions/queries';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import React from 'react';
import ScoreCircle from 'pages/Questions/components/ScoreCircle';


export function jsonStringParse(input: string) {
  const jsonString = input.slice(1, -1);
  const arrayString = `[${jsonString.replace(/\\"/g, '"')}]`;
  return JSON.parse(arrayString);
}


type TProps = {
  submission: ISolution;
};


export default function QuestionScoreCard({ submission }: TProps) {
  const negativeComments: string[] = jsonStringParse(submission?.negative_comments || '');
  const positiveComments: string[] = jsonStringParse(submission?.positive_comments || '');
  return (
    <Card>
      <CardHeader
        title='Score'
        avatar={(
          <Avatar>
            <LightbulbIcon />
          </Avatar>
        )}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <ScoreCircle score={submission.overall_score} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ScoreDescriptionCard title='Strength' comments={positiveComments} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ScoreDescriptionCard title='Weakness' comments={negativeComments} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function ScoreDescriptionCard({
  title, comments,
}:{title: string, comments : string[]}) {
  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title={title}
        avatar={(
          <Avatar style={{
            backgroundColor: title.toLowerCase() === 'weakness' ? theme.palette.error.light : theme.palette.success.light,
            color: '#FFF',
          }}
          >
            {title.toLowerCase() === 'weakness' ? <ThumbDownOffAltIcon /> : <ThumbUpOffAltIcon />}
          </Avatar>
        )}
      />
      <CardContent sx={{ maxHeight: '300px', overflow: 'auto' }}>
        {comments.length > 0 ? (
          <List>
            {comments.map((comment, index) => (
              <ListItem key={comment}>
                <ListItemIcon>
                  <FiberManualRecordIcon sx={{ height: '15px' }} />
                </ListItemIcon>
                <ListItemText primary={comment} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Grid container spacing={2} alignItems='center' justifyContent='center' style={{ height: '100%' }}>
            <Grid item>
              <Typography variant='h6'>No Comments</Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
