import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import CustomizedAccordions from 'pages/HomePage/components/CustomizedAccordion';
import SpinnerButton from 'components/Common/SpinnerButton';
import { GitHub as GitHubIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { TestCase } from 'pages/HomePage/queries';
import Chip from '@mui/material/Chip';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ISolutionTestResult, useCreateTesting } from 'pages/Submissions/queries';
import { ERequestStatus } from 'pages/HomePage/types';
import CodeBlock from 'pages/Questions/components/CodeBlock';
import { ScoreDescriptionCard } from 'pages/Questions/components/QuestionScoreCard';
import Select, { SelectChangeEvent } from '@mui/material/Select';


type TProps = {
  questionId?: string;
  solutionId?: string;
  testCases: TestCase[];
  solutionAvailable: boolean;
  testResults?: ISolutionTestResult[];
  testResultsLoading?: boolean;
  pollingFunc: () => void;
};

// Function to determine chip level
const chipLevel = (complexity: string) => {
  const lowerComplexity = complexity ? complexity.toLowerCase() : '';
  const colorOrder = ['#4CAF5026', '#FFEB3B26', '#F4433626'];

  switch (lowerComplexity) {
    case 'easy':
      return <Chip label='Easy' variant='filled' size='small' sx={{ backgroundColor: colorOrder[0], color: 'black' }} />;
    case 'medium':
      return <Chip label='Medium' variant='filled' size='small' sx={{ backgroundColor: colorOrder[1], color: 'black' }} />;
    case 'hard':
      return <Chip label='Hard' variant='filled' size='small' sx={{ backgroundColor: colorOrder[2], color: 'black' }} />;
    default:
      return <Chip icon={<PlayCircleIcon color='primary' />} color='default' label='No Complexity Level' size='small' variant='outlined' />;
  }
};

const testResultChip = (resultString: string | null) => {
  if (!resultString) {
    return <Chip label='Test Evaluation failed to run' variant='filled' size='small' sx={{ opacity: '60%', backgroundColor: '#F4433626', color: 'black' }} />;
  }

  const match = resultString.match(/(\d+)\/(\d+)/);
  if (!match) return null;

  const passed = parseInt(match[1], 10);
  const total = parseInt(match[2], 10);
  const passRate = (passed / total) * 100;

  let backgroundColor = '';
  if (passRate >= 80) {
    backgroundColor = '#4CAF5026';
  }
  else if (passRate >= 50) {
    backgroundColor = '#FFEB3B26';
  }
  else {
    backgroundColor = '#F4433626';
  }

  return <Chip label={`${resultString} Passed`} variant='filled' size='small' sx={{ opacity: '60%', backgroundColor, color: 'black' }} />;
};

const testScoreChip = (score: number) => {
  let backgroundColor = '';
  if (score >= 80) {
    backgroundColor = '#4CAF5026';
  }
  else if (score >= 50) {
    backgroundColor = '#FFEB3B26';
  }
  else {
    backgroundColor = '#F4433626';
  }

  return <Chip label={`Overall Score: ${score || 0}/100`} variant='filled' size='small' sx={{ opacity: '60%', backgroundColor, color: 'black' }} />;
};

export default function TestEvaluationDetails({
  questionId, solutionId, testCases, solutionAvailable,
  testResults, testResultsLoading, pollingFunc,
}: TProps) {
  const [activeTab, setActiveTab] = useState(0);
  const [menuIndex, setMenuIndex] = useState(0);
  const [testProcessing, setTestProcessing] = useState<boolean>(false);
  const createTestingHook = useCreateTesting(questionId || '', solutionId || '');

  const handleClick = () => {
    setTestProcessing(true);
    createTestingHook.mutate(undefined, {
      onSuccess: () => pollingFunc(),
      onError: () => setTestProcessing(false),
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    if (testResultsLoading) {
      setTestProcessing(true);
    }
    if (testResults && testResults.length === 0 && !testResultsLoading) {
      setTestProcessing(false);
    }
    if (testResults && testResults.length > 0 && testResults[0].status === ERequestStatus.SUCCESS
        && !testResultsLoading) {
      setTestProcessing(false);
    }
    if (testResults && testResults.length > 0 && testResults[0].status === ERequestStatus.PROCESSING
        && !testResultsLoading) {
      setTestProcessing(true);
    }
    else {
      setTestProcessing(false);
    }
  }, [testResults, testResultsLoading]);

  if (testResults && testResults.length > 0 && testResults[0]?.status
      && testResults[0]?.status !== ERequestStatus.PROCESSING
      && !testResultsLoading) {
    const selectedTestResult = testResults[menuIndex] || null;
    return (
      <>
        <CardHeader
          title={(
            <Stack direction='row' spacing={2} alignItems='center' sx={{ mb: '24px' }}>
              <Typography variant='h5'>Test Case Run Results</Typography>
              {testResultChip(selectedTestResult.raw_results)}
            </Stack>
          )}
          subheader={(
            <Typography variant='body2' sx={{ mr: '-200px' }}>
              {selectedTestResult?.error_details && selectedTestResult.error_details.length > 0
                ? selectedTestResult?.error_details : selectedTestResult.evaluation}
            </Typography>
)}
          titleTypographyProps={{ variant: 'h5', fontWeight: 600 }}
          action={(
            <Box sx={{
              minWidth: '200px', ml: 'auto', display: 'flex', justifyContent: 'flex-end',
            }}
            >
              <FormControl sx={{ width: '200px', height: '40px' }}>
                {' '}
                <InputLabel id='status-select-label'>Run History</InputLabel>
                <Select
                  fullWidth
                  labelId='status-select-label'
                  label='Run History'
                  onChange={(event: SelectChangeEvent<number>) => setMenuIndex(
                    typeof event.target.value === 'string' ? parseInt(event.target.value, 10) : event.target.value,
                  )}
                  value={menuIndex}
                  sx={{ fontSize: '14px', height: '40px' }}
                >
                  {testResults.map((result, index) => (
                    <MenuItem key={result.id} value={index}>
                      <Typography variant='body2'>
                        {new Date(result.created_at).toLocaleString()}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        />
        <CardContent>
          <Grid container spacing={2}>
            {/* Result Logs */}
            {!selectedTestResult?.error_details && (
            <Grid item xs={12}>
              <CustomizedAccordions
                title='Evaluation Details'
                subHeader={`${new Date(selectedTestResult.created_at).toLocaleDateString()} ${new Date(selectedTestResult.created_at).toLocaleTimeString()}`}
                content=''
                key='Evaluation Details'
                chip={testScoreChip(selectedTestResult?.score)}
              >
                <Box sx={{
                  borderBottom: 1, borderColor: 'divider', mb: '16px', mt: '-20px',
                }}
                >
                  <Tabs value={activeTab} onChange={handleTabChange} aria-label='test cases tabs'>
                    <Tab label='Result Logs' />
                    <Tab label='Comments' />
                  </Tabs>
                </Box>

                {activeTab === 0 && (
                <CodeBlock
                  sx={{ maxHeight: '300px', border: '1px solid #555' }}
                  code={selectedTestResult.test_results}
                />
                )}

                {activeTab === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ScoreDescriptionCard title='Strength' comments={selectedTestResult.strengths} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ScoreDescriptionCard title='Weakness' comments={selectedTestResult.weaknesses} />
                  </Grid>
                </Grid>
                )}
              </CustomizedAccordions>
            </Grid>
            )}

            {testCases.map((test, index) => {
              const matchedTestCases = selectedTestResult?.test_cases_code
                ? selectedTestResult.test_cases_code
                  .filter((testCase) => Object.keys(testCase).includes(test.title)) : [];

              return (
                <Grid item xs={12} key={`test-case-${test.title}`}>
                  <CustomizedAccordions
                    key={`Test #${index + 1}`}
                    content={test.description}
                    title={test.title}
                    subHeader={`Test #${index + 1}`}
                    chip={chipLevel(test.complexity)}
                  >
                    <CardContent>
                      {matchedTestCases.length > 0 ? (
                        matchedTestCases.map((matchedTestCase, idx) => {
                          const codeSnippet = matchedTestCase['Code Snippet from repository'] || 'No code snippet available.';
                          const tip = matchedTestCase.Tip;

                          return (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <CodeBlock
                                  key={`code-snippet-${test.title}`}
                                  code={codeSnippet}
                                />
                              </Grid>
                              {tip && (
                              <Grid item xs={12}>
                                <Typography>
                                  <strong>Tip:</strong>
                                  {' '}
                                  {tip}
                                </Typography>
                              </Grid>
                              )}
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CodeBlock
                              key={`code-snippet-${test.title}`}
                              code='No code snippet available.'
                            />
                          </Grid>
                        </Grid>
                      )}
                    </CardContent>
                  </CustomizedAccordions>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>

        {/* Evaluate Button */}
        {solutionAvailable && (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <SpinnerButton
              variant='contained'
              startIcon={<GitHubIcon />}
              onClick={handleClick}
              isLoading={testProcessing}
            >
              Evaluate Tests
            </SpinnerButton>
          </CardActions>
        )}
      </>
    );
  }

  return (
    <>
      <CardHeader
        title='Test Cases'
        subheader='Please write unit tests based on the following test cases and include it in your GitHub submission.'
        titleTypographyProps={{ variant: 'h5', fontWeight: 600 }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {testCases.map((test, index) => (
            <Grid item xs={12} key={`test-case-${test.title}`}>
              <CustomizedAccordions
                key={`Test #${index + 1}`}
                content={test.description}
                title={test.title}
                subHeader={`Test #${index + 1}`}
                chip={chipLevel(test.complexity)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
      {solutionAvailable && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <SpinnerButton
            variant='contained'
            startIcon={<GitHubIcon />}
            onClick={handleClick}
            isLoading={testProcessing}
          >
            Evaluate Tests
          </SpinnerButton>
        </CardActions>
      )}
    </>
  );
}
