import React from 'react';
import { useQuery } from '@tanstack/react-query';
import githubService, { GithubRepository } from 'services/githubService';
import RepositorySelect from 'components/Github/RepositorySelect';
import { IQuestion } from 'pages/HomePage/queries';
import { GitHub as GitHubIcon } from '@mui/icons-material';
import SpinnerButton from 'components/Common/SpinnerButton';
import { useNavigate } from 'react-router-dom';
import { connections } from 'utils/spaUrls';


type Props = {
  onSelect: (repo: GithubRepository) => void;
  onUpload: () => void;
  isSubmissionProcessing?: boolean;
  question: IQuestion;
};

export default function GithubRepositoryContainer({
  onSelect, isSubmissionProcessing, question, onUpload,
}: Props) {
  const navigate = useNavigate();
  const { data: statusResponse, isLoading: statusLoading } = useQuery({
    queryKey: ['github-status'],
    queryFn: githubService.getStatus,
  });

  const githubStatusLoading = Boolean(!statusResponse || statusLoading);

  if (statusResponse?.data?.isLinked) {
    return (
      <RepositorySelect
        onSelect={onSelect}
        isLoading={isSubmissionProcessing}
        question={question}
        onSubmit={onUpload}
      />
    );
  }

  return (
    <SpinnerButton
      variant='contained'
      startIcon={<GitHubIcon />}
      onClick={() => navigate(connections.github)}
      isLoading={githubStatusLoading}
    >
      {githubStatusLoading ? 'Checking GitHub Connection' : 'Connect GitHub Account'}
    </SpinnerButton>
  );
}
