import { TimeAudit, UserAudit } from 'common';
import {
  application, coaching, questions,
} from 'utils/apiUrls';
import http from 'utils/http';
import {
  useMutation, UseMutationOptions, useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  EPosition, ESource, ERequestStatus, EExperience,
} from 'pages/HomePage/types';
import { TApiListResponse } from 'utils/apiResponse.type';
import { ISolution } from 'pages/Submissions/queries';


export interface IApplication {
    id: string;
    jd_url?: string;
    location?: string;
    salary?: string;
    role: EPosition;
    skills: string[];
    company: ICompany;
    job_posted: string;
    job_applied: string;
    job_description_id: string;
    leetcode_questions: string[] | null;
    exp_level: EExperience;
    created_at?: string;
    time_audit?: TimeAudit;
    user_audit?: UserAudit;
    request_status: ERequestStatus;
    error_details: string | null;
}

export interface ICompany {
    name: string;
    url?: string;
    size?: string;
    industry?: string;
    about?: string;
    logo?: string;
}

export interface ProfilePayload {
    profiles: IMentorProfile[];
}

export interface IMentorProfile {
    name: string;
    image: string;
    linkedInUrl: string;
    experience: string;
    position: string;
    skills: string[];
}

export interface IQuestion {
    id: string;
    application: IApplication | null;
    company: ICompany | null;
    title: string;
    description: string;
    skills: string[];
    duration: number;
    bookmark: boolean | null;
    submission: ISolution | null;
    resume_points: string | null;
    test_cases?: TestCase[];
}

export interface TestCase {
    title: string;
    complexity: string;
    description: string;
}

export interface IStatus<T> {
    key: T;
    title: string;
    color: string;
}


export interface ICreateApplicationRequest {
    url: string;
    source: ESource;
}

export interface ICreateQuestionRequest {
    application_id: string;
    type: string;
}

export interface IQuestionBookmarkRequest {
    question_id: string;
    bookmark: boolean;
}

export const userApplicationListQueryKey = () => ['users', 'applications', 'list'];

export function useGetUserApplicationList(
  options?: UseQueryOptions<TApiListResponse<IApplication>, Error>,
) {
  return useQuery<TApiListResponse<IApplication>, Error>(
    {
      queryKey: userApplicationListQueryKey(),
      queryFn: () => http.get(application.list),
      retry: false,
      ...options,
    },
  );
}

export const userApplicationQueryKey = (applicationId: string) => ['users', 'applications', applicationId];

export function useGetUserApplication(
  applicationId: string,
  options?: UseQueryOptions<TApiListResponse<IApplication>, Error>,
) {
  return useQuery<TApiListResponse<IApplication>, Error>(
    {
      queryKey: userApplicationQueryKey(applicationId),
      queryFn: () => http.get(application.details(applicationId)),
      retry: false,
      ...options,
    },
  );
}

export function useCreateApplication(
  options?: UseMutationOptions<void, Error, ICreateApplicationRequest>,
) {
  return useMutation<void, Error, ICreateApplicationRequest>(
    {
      mutationFn: (app) => http.post(
        application.create,
        app,
      ),
      ...options,
    },
  );
}

export const userApplicationQuestionsQueryKey = (applicationId: string) => ['user', 'application', applicationId, 'questions', 'list'];

export function useGetApplicationQuestions(
  applicationId: string,
  options?: UseQueryOptions<TApiListResponse<IQuestion>, Error>,
) {
  return useQuery<TApiListResponse<IQuestion>, Error>(
    {
      queryKey: userApplicationQuestionsQueryKey(applicationId),
      queryFn: () => http.get(questions.details(applicationId)),
      refetchInterval: 10000,
      ...options,
    },
  );
}

export const userQuestionsQueryKey = () => ['user', 'questions', 'list'];

export function useGetUserQuestions(
  options?: UseQueryOptions<TApiListResponse<IQuestion>, Error>,
) {
  return useQuery<TApiListResponse<IQuestion>, Error>(
    {
      queryKey: userQuestionsQueryKey(),
      queryFn: () => http.get(questions.list),
      ...options,
    },
  );
}

export function useCreateQuestions(
  options?: UseMutationOptions<void, Error, ICreateQuestionRequest>,
) {
  return useMutation<void, Error, ICreateQuestionRequest>(
    {
      mutationFn: (app) => http.post(
        questions.create,
        app,
      ),
      ...options,
    },
  );
}

export function useQuestionBookmark(
  options?: UseMutationOptions<void, Error, IQuestionBookmarkRequest>,
) {
  return useMutation<void, Error, IQuestionBookmarkRequest>(
    {
      mutationFn: (app) => http.post(
        questions.bookmark(app.question_id),
        { bookmark: app.bookmark },
      ),
      ...options,
    },
  );
}

export const userQuestionQueryKey = (questionId: string) => [questionId, 'question'];

export function useGetQuestion(
  questionId: string,
  options?: UseQueryOptions<IQuestion, Error>,
) {
  return useQuery<IQuestion, Error>(
    {
      queryKey: userQuestionQueryKey(questionId),
      queryFn: () => http.get(questions.getQuestion(questionId)).then((resp) => resp.data),
      ...options,
    },
  );
}

export function useDeleteQuestion(
  options?: UseMutationOptions<void, Error, string>,
) {
  return useMutation<void, Error, string>(
    {
      mutationFn: (questionId) => http.delete(
        questions.delete(questionId),
      ),
      ...options,
    },
  );
}

export function useAddInterestedUser(
  options?: UseMutationOptions<void, Error, NonNullable<unknown>>,
) {
  return useMutation<void, Error, NonNullable<unknown>>(
    {
      mutationFn: () => http.post(
        coaching.createInterest,
        {},
      ),
      ...options,
    },
  );
}

export function useDeleteApplication(
  options?: UseMutationOptions<void, Error, string>,
) {
  return useMutation<void, Error, string>(
    {
      mutationFn: (applicationId) => http.delete(
        application.delete(applicationId),
      ),
      ...options,
    },
  );
}
