import {
  Card,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { TestCase } from 'pages/HomePage/queries';
import TestShowDetails from 'pages/Questions/components/TestShowDetails';

// Styling for the card to make it look cleaner
const StyledCard = styled(Card)({
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  padding: '16px',
  backgroundColor: '#fff',
});

type TProps = {
    testCases: TestCase[];
};


export default function QuestionTestCases({ testCases }: TProps) {
  return (
    <StyledCard>
      <TestShowDetails testCases={testCases} />
    </StyledCard>
  );
}
