import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Stack,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import theme from 'theme';
import Avatar from '@mui/material/Avatar';
import BugReportIcon from '@mui/icons-material/BugReport';
import Tooltip from '@mui/material/Tooltip';


const StyledAccordion = styled(Accordion)({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
});

type TProps = {
  title: string;
  subHeader?: string;
  content: string;
  key: string;
  subContent?: string;
  tooltip?: string;
  chip?: React.ReactNode;
  children?: React.ReactNode;
}


export default function CustomizedAccordions({
  title, content, key, subHeader, chip, tooltip, children, subContent,
}: TProps) {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <StyledAccordion expanded={expanded} onChange={() => setExpanded(!expanded)} key={key}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} alignItems='flex-start'>
              <Avatar>
                <BugReportIcon />
              </Avatar>
              <Stack direction='column'>
                <Typography variant='h6'>{title}</Typography>
                {subHeader && (
                <Tooltip title={tooltip}>
                  <Typography variant='caption'>{subHeader}</Typography>
                </Tooltip>
                )}
              </Stack>
              {chip}
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant='body1'>{content}</Typography>
          </Grid>
          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );
}
