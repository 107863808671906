import { TApiListResponse, TApiResponse } from 'utils/apiResponse.type';
import http from 'utils/http';
import {
  useMutation, UseMutationOptions, useQuery, UseQueryOptions,
} from '@tanstack/react-query';
import { job } from 'utils/apiUrls';
import { EExperience, EPosition } from 'pages/HomePage/types';


export interface IJobDetails {
    id: string,
    source: string,
    qualifications: string[],
    responsibility: string[],
    companyName: string,
    industryDomain: string,
    location: string,
    salary: string | null,
    type: string,
    date_posted: string | null,
    skills: string[],
    leetcode_questions: string[],
    description: string,
    about_company: string,
    company_url: string,
    logo: string,
    experience_level: EExperience,
    role: EPosition,
    jd_url: string,
    socials: string[],
    num_of_employees: string,
    categories: string[],
    category_groups: string[],
    job_post_timestamp: string,
    title: string,
}

export interface JobFiltersItems {
    position?: string;
    company?: string;
    skill?: string;
    postedDate?: string;
}

export const userJobListQueryKey = () => ['users', 'jobs', 'list'];

export function useGetJobList(
  options?: UseQueryOptions<TApiListResponse<IJobDetails>, Error>,
) {
  return useQuery<TApiListResponse<IJobDetails>, Error>(
    {
      queryKey: userJobListQueryKey(),
      queryFn: () => http.get(job.list),
      retry: false,
      ...options,
    },
  );
}

export const useJobDetailsQueryKey = (jobId: string) => ['users', 'job', 'details', jobId];

export function useGetJobDetails(
  jobId: string,
  options?: UseQueryOptions<TApiResponse<IJobDetails>, Error>,
) {
  return useQuery<TApiResponse<IJobDetails>, Error>(
    {
      queryKey: useJobDetailsQueryKey(jobId),
      queryFn: () => http.get(job.details(jobId)),
      retry: false,
      ...options,
    },
  );
}

export const userJobListAppliedQueryKey = () => ['users', 'jobs', 'applied'];

export function useGetJobsApplied(
  options?: UseQueryOptions<TApiListResponse<string>, Error>,
) {
  return useQuery<TApiListResponse<string>, Error>(
    {
      queryKey: userJobListAppliedQueryKey(),
      queryFn: () => http.get(job.applied),
      retry: false,
      ...options,
    },
  );
}

export function useTrackJobListing(
  jobId: string,
  options?: UseMutationOptions<void, Error>,
) {
  return useMutation<void, Error, void>(
    {
      mutationFn: () => http.post(
        job.track(jobId),
        {},
      ),
      ...options,
    },
  );
}
