import { Box, Grid, Typography } from '@mui/material';
import EmptyStateSvg from 'assets/EmptyState.svg';
import React from 'react';


export default function EmptyConnectionPage() {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Box
          component='img'
          src={EmptyStateSvg}
          alt='No Applications'
          sx={{ height: '148px', width: '148px', marginBottom: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '605px', textAlign: 'center', margin: '0 auto' }}>
          <Typography variant='h5' gutterBottom>
            No Github Connection
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            Codei requires you to connect to your github account.
            This allows Codei to access Public and Private Repository that you own.
            This is required for seamless evaluation of your Github Submission
            to Codei&apos;s AI-Generated Job-relevant Questions
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
