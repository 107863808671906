import {
  Card, Grid, useMediaQuery,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Spinner from 'components/Common/Spinner';
import { positionParsed } from 'pages/HomePage/constants';
import stringToDate from 'components/Common/Date';
import { useLocation } from 'react-router-dom';
import EmptyJobs from 'pages/JobBoard/components/EmptyJobs';
import { jobs } from 'utils/spaUrls';
import JobListingView from 'pages/JobBoard/components/JobListingView';
import {
  IJobDetails, JobFiltersItems, useGetJobList,
  useGetJobsApplied, userJobListAppliedQueryKey, userJobListQueryKey,
} from 'pages/JobBoard/queries';
import { JobFilters } from 'pages/JobBoard/components/JobFilters';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function JobList() {
  const query = useQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const company = query.get('company') || undefined;
  const position = query.get('position') || undefined;
  const skill = query.get('skill') || undefined;
  const postedDate = query.get('job_posted') || undefined;

  const {
    data: jobListData,
    isLoading: jobListLoading,
  } = useGetJobList({
    queryKey: userJobListQueryKey(),
  });

  const {
    data: jobsAppliedData,
  } = useGetJobsApplied({
    queryKey: userJobListAppliedQueryKey(),
  });

  if (!jobListData || jobListLoading) {
    return <Spinner />;
  }

  const showEmptyState = jobListData.data.length === 0;

  const filteredJobListings = applyFilters(jobListData.data, {
    company, position, skill, postedDate,
  });
  const tabs = [
    { label: 'All', value: '', count: filteredJobListings.length },
  ] as const;
  return (
    <Grid container spacing={2} sx={{ flexGrow: 1, padding: 2 }}>
      <Grid item container xs={12} alignItems='flex-end' justifyContent='space-between' sx={{ mb: 2 }}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>Job Listings</Typography>
      </Grid>
      {showEmptyState ? (
        <Grid item xs={12}>
          <EmptyJobs />
        </Grid>
      ) : (
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <JobFilters
                filters={{
                  company, position, skill, postedDate,
                }}
                filterTabs={tabs}
                navigateUrl={jobs.list}
              />
            </Card>
          </Grid>
          {filteredJobListings.length === 0 ? (
            <Grid item xs={12}>
              <EmptyJobs />
            </Grid>
          ) : (
            <Grid item xs={12} container spacing={2}>
              {filteredJobListings.map((
                jobListing,
              ) => (
                <JobListingView
                  key={jobListing.id}
                  jobListing={jobListing}
                  appliedFlag={jobsAppliedData?.data.some((
                    specificJob,
                  ) => specificJob === jobListing.id)}
                />
              ))}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

function applyFilters(
  row: IJobDetails[],
  {
    company, position, skill, postedDate,
  }: JobFiltersItems,
): IJobDetails[] {
  return row.filter((item) => {
    if (company) {
      if (!item.companyName.toLowerCase().includes(company.toLowerCase())) {
        return false;
      }
    }

    if (position) {
      const parsedPosition = positionParsed.parseFromTitle(position.toLowerCase());
      if (!item.role || !item.role.toLowerCase().includes(parsedPosition.toLowerCase())) {
        return false;
      }
    }

    if (skill) {
      const lowerCaseSkill = skill.toLowerCase();
      const skillsLowerCase = item.skills && item.skills.map((s) => s.toLowerCase());
      if (skillsLowerCase) {
        if (!skillsLowerCase.includes(lowerCaseSkill)) {
          return false;
        }
      }
    }

    if (postedDate) {
      const postedJobDate = stringToDate({ dateString: postedDate, formatStr: 'MMMM d, yyyy' });
      if (item.job_post_timestamp) {
        const jobPostedDate = stringToDate({ dateString: item.job_post_timestamp, formatStr: 'MMMM d, yyyy' });
        if (jobPostedDate && postedJobDate) {
          if (jobPostedDate !== postedJobDate) {
            return false;
          }
        }
      }
    }

    return true;
  });
}
