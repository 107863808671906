import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Paper,
  Tabs,
  Tab,
  Toolbar,
  Stack,
} from '@mui/material';
import { DiffEditor } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { useEffect, useRef, useState } from 'react';
import {
  ChevronRight,
  KeyboardArrowDown,
  Code,
  FolderOpen,
  InsertDriveFile,
  Close,
  Javascript,
  Html,
  Css,
  Description,
  Terminal,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { buildFileTree, TreeNode } from 'pages/Questions/utils/fileTree';
import Spinner from 'components/Common/Spinner';
import { useGetSolutionFiles, userSolutionFileKey } from 'pages/Submissions/queries';
import { Logger } from 'utils/logger';


const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '40px',
  textTransform: 'none',
  fontSize: '0.875rem',
  padding: '0 16px',
  color: theme.palette.grey[400],
  '&.Mui-selected': {
    color: theme.palette.common.white,
  },
}));

const fileIconMap: Record<string, JSX.Element> = {
  js: <Javascript sx={{ fontSize: 20, color: '#ffca28' }} />,
  css: <Css sx={{ fontSize: 20, color: '#42a5f5' }} />,
  html: <Html sx={{ fontSize: 20, color: '#ff7043' }} />,
  sh: <Terminal sx={{ fontSize: 20, color: '#4caf50' }} />,
  go: <Code sx={{ fontSize: 20, color: '#00ADD8' }} />,
  java: <Code sx={{ fontSize: 20, color: '#b07219' }} />,
  md: <Description sx={{ fontSize: 20, color: '#42a5f5' }} />,
};

function FileIcon({ fileName }: { fileName: string }) {
  const extension = fileName.split('.').pop()?.toLowerCase();
  if (!extension) {
    return <InsertDriveFile sx={{ fontSize: 20, color: '#9e9e9e' }} />;
  }
  return fileIconMap[extension] || <InsertDriveFile sx={{ fontSize: 20, color: '#9e9e9e' }} />;
}

async function fetchFileContent(url: string | null): Promise<string | null> {
  if (!url) return null;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      Logger.error(`Failed to fetch content from ${url}: ${response.statusText}`);
    }
    return await response.text();
  }
  catch (error) {
    Logger.error(`Failed to fetch content from ${url}`, error);
    return null;
  }
}

function detectLanguage(fileName: string): string {
  const extension = fileName.split('.').pop()?.toLowerCase();
  const languageMap: Record<string, string> = {
    js: 'javascript',
    css: 'css',
    html: 'html',
    sh: 'shell',
    go: 'go',
    java: 'java',
    md: 'markdown',
  };
  return languageMap[extension || ''] || 'plaintext';
}

function getModifiedRanges(
  modified: string,
  improvements: { original_code: string; improved_code: string; start_line: number;
        end_line: number; reasoning: string; criterion: string }[],
): { range: monaco.Range; improvement: { original_code: string;
    improved_code: string; start_line: number;
        end_line: number; reasoning: string; criterion: string } }[] {
  const modifiedLines = modified.split('\n');
  const combinedModified = modifiedLines.join('\n');
  const results: { range: monaco.Range; improvement: { original_code: string;
      improved_code: string; start_line: number;
            end_line: number; reasoning: string; criterion: string } }[] = [];

  improvements.forEach((improvement) => {
    // eslint-disable-next-line camelcase
    const { improved_code } = improvement;

    // Create a regex to match all occurrences of the improved code
    const regex = new RegExp(
      // eslint-disable-next-line camelcase
      improved_code
        .replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Escape special regex characters
        .replace(/\s+/g, '\\s*'), // Allow whitespace to match across lines
      'gm', // Global and multiline matching
    );

    // Find all matches
    let match;
    while ((match = regex.exec(combinedModified)) !== null) {
      const matchStart = match.index;

      // Determine the starting and ending lines and columns
      let currentLength = 0;
      let startLine = -1;
      let startColumn = 1;
      let endLine = -1;
      let endColumn = 1;

      for (let i = 0; i < modifiedLines.length; i += 1) {
        currentLength += modifiedLines[i].length + 1; // Add 1 for newline character
        if (startLine === -1 && matchStart < currentLength) {
          startLine = i + 1; // Convert to 1-based index
          startColumn = matchStart - (currentLength - modifiedLines[i].length - 1) + 1;
        }
        if (startLine !== -1 && matchStart + match[0].length <= currentLength) {
          endLine = i + 1; // Convert to 1-based index
          endColumn = matchStart + match[0].length - (currentLength - modifiedLines[i].length - 1);
          break;
        }
      }

      if (startLine !== -1 && endLine !== -1) {
        results.push({
          range: new monaco.Range(startLine, startColumn, endLine, endColumn),
          improvement,
        });
      }
    }
  });

  return results;
}

function FileTreeItem({
  node,
  level = 0,
  selectedFile,
  onFileSelect,
  expandedFolders,
  onToggleFolder,
}: {
    node: TreeNode;
    level?: number;
    selectedFile: string;
    onFileSelect: (node: TreeNode) => void;
    expandedFolders: Set<string>;
    onToggleFolder: (path: string) => void;
}) {
  const isExpanded = expandedFolders.has(node.path);
  const isFile = node.type === 'file';

  return (
    <>
      <ListItemButton
        onClick={() => (isFile ? onFileSelect(node) : onToggleFolder(node.path))}
        sx={{
          py: 0.5,
          pl: level * 1.5 + 1,
          gap: '4px',
          '&.Mui-selected': {
            bgcolor: '#37373d',
          },
        }}
        selected={isFile && selectedFile === node.path}
      >
        <ListItemIcon sx={{ minWidth: 'auto', mr: 0.5 }}>
          {!isFile && (
            isExpanded ? (
              <KeyboardArrowDown sx={{ color: 'grey.500', fontSize: 20 }} />
            ) : (
              <ChevronRight sx={{ color: 'grey.500', fontSize: 20 }} />
            )
          )}
        </ListItemIcon>
        <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
          {isFile ? <FileIcon fileName={node.name} /> : <FolderOpen sx={{ color: '#42a5f5', fontSize: 20 }} />}
        </ListItemIcon>
        <ListItemText
          primary={node.name}
          primaryTypographyProps={{
            sx: { color: 'common.white', fontSize: '0.875rem' },
          }}
        />
      </ListItemButton>
      {!isFile && isExpanded && node.children?.map((child) => (
        <FileTreeItem
          key={child.path}
          node={child}
          level={level + 1}
          selectedFile={selectedFile}
          onFileSelect={onFileSelect}
          expandedFolders={expandedFolders}
          onToggleFolder={onToggleFolder}
        />
      ))}
    </>
  );
}

export default function FileView({ solutionId }: { solutionId: string }) {
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [shouldPoll, setShouldPoll] = useState(true);
  const [expandedFolders, setExpandedFolders] = useState<Set<string>>(new Set());
  const [openTabs, setOpenTabs] = useState<string[]>([]);
  const [currentOriginal, setCurrentOriginal] = useState<string | null>(null);
  const [currentModified, setCurrentModified] = useState<string | null>(null);
  const [loadingFileContent, setLoadingFileContent] = useState(false);
  const editorRef = useRef<monaco.editor.IStandaloneDiffEditor | null>(null);

  const { data: fileData, isLoading: fileDataLoading } = useGetSolutionFiles(solutionId || '', {
    queryKey: userSolutionFileKey(solutionId || ''),
    refetchInterval: shouldPoll ? 5000 : false,
    enabled: Boolean(solutionId),
  });

  useEffect(() => {
    if (fileData && fileData.data && fileData.data.evaluation_details
        && fileData.data.evaluation_details.code_comments.files) {
      setShouldPoll(false);
    }
  }, [fileData]);

  const fileTree = fileData?.data && fileData.data?.evaluation_details
    && fileData.data.evaluation_details?.directory_structure
    ? buildFileTree(fileData.data.evaluation_details.directory_structure)
    : [];

  const handleFileSelect = async (node: TreeNode) => {
    if (node.type === 'file') {
      setSelectedFile(node.path);
      setLoadingFileContent(true);

      const [original, modified] = await Promise.all([
        fetchFileContent(node.original ?? null),
        fetchFileContent(node.modified ?? null),
      ]);

      setCurrentOriginal(original);
      setCurrentModified(modified);
      setLoadingFileContent(false);

      if (!openTabs.includes(node.path)) {
        setOpenTabs([...openTabs, node.path]);
      }
    }
  };

  const handleToggleFolder = (path: string) => {
    setExpandedFolders((prev) => {
      const next = new Set(prev);
      if (next.has(path)) next.delete(path);
      else next.add(path);
      return next;
    });
  };

  const handleTabClose = (event: React.MouseEvent, path: string) => {
    event.stopPropagation();
    const newTabs = openTabs.filter((tab) => tab !== path);
    setOpenTabs(newTabs);
    if (selectedFile === path) {
      setSelectedFile(newTabs.length > 0 ? newTabs[newTabs.length - 1] : '');
    }
  };

  const handleEditorMount = (editor: monaco.editor.IStandaloneDiffEditor) => {
    editorRef.current = editor;
    const modifiedModel = editor.getModel()?.modified;
    const originalModel = editor.getModel()?.original;

    if (modifiedModel && originalModel && currentOriginal && currentModified) {
      const fileName = selectedFile.split('/').pop();
      const fileComments = fileData?.data?.evaluation_details?.code_comments?.files?.find(
        (file) => file.file_path === fileName,
      );
      const ranges = getModifiedRanges(currentModified, fileComments?.improvements || []);

      if (fileComments && fileComments.improvements) {
        modifiedModel.deltaDecorations([], ranges.map((
          { range, improvement },
        ) => ({
          range,
          options: {
            isWholeLine: true,
            className: 'modified-line-highlight',
            glyphMarginClassName: 'modified-glyph-margin',
            hoverMessage: {
              value: `**Reasoning ${improvement.criterion}:**\n ${improvement.reasoning}`,
            },
          },
        })));
      }
    }
  };

  let content;

  if (loadingFileContent) {
    content = <Spinner />;
  }
  else if (currentOriginal && currentModified) {
    content = (
      <Box sx={{ flexGrow: 1, bgcolor: '#1e1e1e' }}>
        <DiffEditor
          height='100%'
          language={detectLanguage(selectedFile)}
          original={currentOriginal}
          modified={currentModified}
          theme='vs-dark'
          options={{
            fontSize: 14,
            renderSideBySide: true,
            enableSplitViewResizing: true,
            originalEditable: false,
            minimap: { enabled: true },
            scrollBeyondLastLine: false,
            automaticLayout: true,
            padding: { top: 10 },
            lineNumbers: 'on',
            renderWhitespace: 'selection',
            glyphMargin: true,
            scrollbar: {
              vertical: 'visible',
              horizontal: 'visible',
              verticalScrollbarSize: 10,
              horizontalScrollbarSize: 10,
            },
          }}
          onMount={handleEditorMount}
        />
      </Box>
    );
  }
  else {
    content = (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: '#1e1e1e',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          color: 'grey.500',
        }}
      >
        <InsertDriveFile sx={{ fontSize: 48, opacity: 0.5 }} />
        <Typography variant='h6' sx={{ opacity: 0.5 }}>
          {fileDataLoading ? 'Files are still loading...' : 'Select a file to view and edit'}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh', bgcolor: 'background.default' }}>
      <Box
        sx={{
          width: 210,
          bgcolor: '#252526',
          borderRight: '1px solid #2d2d2d',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar sx={{ minHeight: '48px !important' }}>
          <Typography
            variant='caption'
            sx={{
              textTransform: 'uppercase',
              color: 'grey.500',
              fontWeight: 500,
              letterSpacing: 1,
            }}
          >
            Explorer
          </Typography>
        </Toolbar>
        <List disablePadding>
          {fileTree.map((node) => (
            <FileTreeItem
              key={node.path}
              node={node}
              selectedFile={selectedFile}
              onFileSelect={handleFileSelect}
              expandedFolders={expandedFolders}
              onToggleFolder={handleToggleFolder}
            />
          ))}
        </List>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper
          square
          elevation={0}
          sx={{
            bgcolor: '#252526',
            borderBottom: '1px solid #2d2d2d',
          }}
        >
          <Tabs
            value={selectedFile}
            onChange={(_, value) => setSelectedFile(value)}
            variant='scrollable'
            scrollButtons='auto'
            sx={{
              minHeight: '40px',
              '& .MuiTabs-indicator': { display: 'none' },
            }}
          >
            {openTabs.map((path) => (
              <StyledTab
                key={path}
                value={path}
                label={(
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    sx={{ minWidth: 120, maxWidth: 200 }}
                  >
                    <FileIcon fileName={path.split('/').pop() || ''} />
                    <Typography
                      noWrap
                      component='span'
                      sx={{ fontSize: '0.875rem', flexGrow: 1 }}
                    >
                      {path.split('/').pop()}
                    </Typography>
                    <IconButton
                      size='small'
                      onClick={(e) => handleTabClose(e, path)}
                      sx={{ p: 0.5, color: 'grey.500' }}
                    >
                      <Close sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Stack>
                                )}
                sx={{ bgcolor: selectedFile === path ? '#1e1e1e' : '#2d2d2d' }}
              />
            ))}
          </Tabs>
        </Paper>
        {content}
      </Box>
    </Box>
  );
}
