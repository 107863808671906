import React from 'react';
import { GitHub as GitHubIcon } from '@mui/icons-material';
import SpinnerButton from 'components/Common/SpinnerButton';


type Props = {
  isLoading?: boolean;
};

const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID ?? (() => {
  throw new Error('REACT_APP_GITHUB_CLIENT_ID is not defined');
})();

const redirectUri = process.env.REACT_APP_GITHUB_REDIRECT_URI ?? (() => {
  throw new Error('REACT_APP_GITHUB_REDIRECT_URI is not defined');
})();

export default function GithubConnect({ isLoading }: Props) {
  const handleConnect = () => {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=repo%20workflow`;
  };

  return (
    <SpinnerButton
      variant='contained'
      startIcon={<GitHubIcon />}
      onClick={handleConnect}
      isLoading={isLoading}
    >
      {isLoading ? 'Checking Github Account' : 'Connect GitHub Account'}
    </SpinnerButton>
  );
}
