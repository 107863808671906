import { Grid, Stack, Typography } from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import {
  userApplicationQueryKey,
} from 'pages/HomePage/queries';
import Spinner from 'components/Common/Spinner';
import React, { useState } from 'react';
import { experienceParsed, positionParsed } from 'pages/HomePage/constants';
import CustomChip from 'pages/HomePage/components/CustomChip';
import stringToDate from 'components/Common/Date';
import ApplicationInfoCard from 'pages/HomePage/components/ApplicationInfoCard';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import theme from 'theme';
import JobDetailsHeader from 'pages/JobBoard/components/JobDetailsHeader';
import JobDetailsCard from 'pages/JobBoard/components/JobDetailsCard';
import { useGetJobDetails, useGetJobsApplied, useTrackJobListing } from 'pages/JobBoard/queries';
import TrackJobModal from 'pages/JobBoard/components/TrackJobModal';


export default function JobDetails() {
  const { jobId } = useParams<{ jobId: string }>();
  const trackJobListingHook = useTrackJobListing(jobId || '');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {
    data: jobData,
    isLoading,
  } = useGetJobDetails(jobId || '', {
    queryKey: userApplicationQueryKey(jobId || ''),
    enabled: Boolean(jobId),
  });

  const {
    data: jobAppliedData,
  } = useGetJobsApplied();

  if (!jobData || isLoading || !jobData?.data) {
    return <Spinner />;
  }

  const validCompanyUrl = Boolean(jobData.data?.company_url && jobData.data.company_url.includes('https'));

  const companyUrlValue = validCompanyUrl ? (
    <Link component={NavLink} to={jobData.data.company_url || 'none'} target='_blank'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <OpenInNewIcon fontSize='small' />
        <Typography>{jobData.data.companyName}</Typography>
      </Stack>
    </Link>
  ) : 'None';

  const companyDetails = [
    { key: 'Description', value: jobData.data.about_company },
    { key: 'Company', value: jobData.data.companyName },
    {
      key: 'Company Url',
      value: companyUrlValue,
    },
    { key: 'Industry', value: jobData.data.industryDomain },
    { key: 'Size', value: jobData.data.num_of_employees },
  ].filter((item) => item.value !== undefined && item.value !== null && item.value.toString().toLowerCase() !== 'none');

  const validJobUrl = Boolean(jobData.data?.jd_url && jobData.data.jd_url.includes('https'));

  const jobUrlValue = validJobUrl ? (
    <Link component={NavLink} to={jobData.data.jd_url || ''} target='_blank'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <OpenInNewIcon fontSize='small' style={{ color: theme.palette.text.secondary }} />
        <Typography>External Link</Typography>
      </Stack>
    </Link>
  ) : 'None';

  const applicationDetails = [
    { key: 'Job Posted', value: stringToDate({ dateString: jobData.data.job_post_timestamp, formatStr: 'MMMM d, yyyy' }) },
    { key: 'Location', value: jobData.data.location },
    { key: 'Salary', value: jobData.data.salary },
    { key: 'Experience Level', value: experienceParsed.parse(jobData.data.experience_level).title },
    { key: 'Skills', value: <CustomChip skills={jobData.data.skills} renderNumber={3} /> },
    {
      key: 'Job Url',
      value: jobUrlValue,
    },
  ].filter((item) => item.value !== undefined && item.value !== null && item.value.toString().toLowerCase() !== 'none');

  const chipTime = () => {
    // Get the current time and the job posted time
    const currentTime = new Date();
    const jobPostedTime = new Date(jobData.data.job_post_timestamp);

    // Calculate the difference in milliseconds
    const timeDifference = currentTime.getTime() - jobPostedTime.getTime();

    // Convert the difference into seconds, minutes, hours, days, and months
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = currentTime.getMonth() - jobPostedTime.getMonth()
        + (12 * (currentTime.getFullYear() - jobPostedTime.getFullYear()));

    // Determine how to display the time difference
    if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    }
    else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    else {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    }
  };


  const handleTrackClick = () => {
    if (jobAppliedData && jobAppliedData.data.some((specificJob) => specificJob === jobId)) {
      setModalOpen(false);
    }
    else {
      setModalOpen(true);
    }
  };

  const handleGenerateClick = (response: boolean) => {
    if (jobId && response) {
      trackEvents(EEvents.CLICK, {
        feature: 'JobDetails - JobListing',
        type: 'Button',
        label: 'Track Job Listing',
      });
      trackJobListingHook.mutate();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <JobDetailsHeader
          jobPosted={chipTime()}
          jobTitle={positionParsed.parse(jobData.data.role).title}
          companyName={jobData.data.companyName}
          logo={jobData.data.logo}
          onButtonClick={handleTrackClick}
          jobUrl={jobData.data.jd_url || ''}
        />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <JobDetailsCard jobDetails={jobData.data} />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} spacing={2}>
          <Grid item xs={12}>
            <ApplicationInfoCard title='Application Details' details={applicationDetails} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <ApplicationInfoCard title='Company Details' details={companyDetails} />
        </Grid>
      </Grid>
      {modalOpen && (
        <TrackJobModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onTrack={handleGenerateClick}
        />
      )}
    </Grid>
  );
}
