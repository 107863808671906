export type TreeNode = {
  name: string;
  path: string;
  type: 'file' | 'directory';
  children?: TreeNode[];
  original?: string;
  modified?: string;
  language?: string;
};


function detectLanguage(fileName: string): string | undefined {
  const extension = fileName.split('.').pop()?.toLowerCase();

  if (!extension) return undefined;

  const languageMap: Record<string, string> = {
    js: 'javascript',
    ts: 'typescript',
    css: 'css',
    html: 'html',
    py: 'python',
    go: 'go',
    java: 'java',
    c: 'c',
    cpp: 'cpp',
    cs: 'csharp',
    rb: 'ruby',
    php: 'php',
    rs: 'rust',
    swift: 'swift',
    kt: 'kotlin',
    sh: 'shell',
    md: 'markdown',
    json: 'json',
    xml: 'xml',
  };

  return languageMap[extension] || undefined;
}

export function buildFileTree(
  /* eslint-disable @typescript-eslint/no-explicit-any */
  directoryStructure: Record<string, any>,
  parentPath = '',
): TreeNode[] {
  const tree: TreeNode[] = [];

  Object.entries(directoryStructure).forEach(([key, value]) => {
    const currentPath = parentPath ? `${parentPath}/${key}` : key;

    if (!value) {
      return;
    }

    if (typeof value === 'object' && !value.original_file && !value.eval_file) {
      const children = buildFileTree(value, currentPath);
      if (children.length > 0) {
        tree.push({
          name: key,
          path: currentPath,
          type: 'directory',
          children,
        });
      }
    }
    else if (value.original_file) {
      tree.push({
        name: key,
        path: currentPath,
        type: 'file',
        original: value.original_file,
        modified: value.eval_file || null,
        language: detectLanguage(key),
      });
    }
  });

  return tree;
}
