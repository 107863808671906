import React from 'react';
import {
  Box,
  Dialog,
  Grid,
  Typography,
  SxProps,
  Theme,
  IconButton,
  Button,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Work } from '@mui/icons-material';


type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onTrack: (response: boolean) => void;
};

export default function TrackJobModal({ isOpen, onClose, onTrack }: TProps) {
  const handleTrack = (response: boolean) => {
    onTrack(response);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth='xs'
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <Box sx={{ position: 'relative', p: 4, textAlign: 'center' }}>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 12, top: 12 }}
        >
          <CloseIcon />
        </IconButton>

        <Avatar sx={{
          bgcolor: '#e0e0e0',
          width: 56,
          height: 56,
          mb: 2,
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center',
          mx: 'auto',
        }}
        >
          <Work fontSize='large' sx={{ color: 'black' }} />
        </Avatar>

        <Typography variant='h6' sx={{ fontWeight: 600, mb: 1 }}>
          Track Job Listing
        </Typography>

        <Typography variant='body1' sx={{ mb: 3, color: 'text.secondary' }}>
          Did you apply to this job? Should Codei track this job and recommend similar jobs to you?
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant='contained'
              fullWidth
              onClick={() => handleTrack(true)}
              sx={{
                borderRadius: 2, textTransform: 'none', fontWeight: 600, py: 1.5,
              }}
            >
              Yes, I did apply
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant='outlined'
              fullWidth
              onClick={() => handleTrack(false)}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
                py: 1.5,
              }}
            >
              No, I didn’t apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

const dialogPaperStyle: SxProps<Theme> = {
  borderRadius: 4,
  boxShadow: 6,
  m: 2,
};
