import http from 'utils/http';
import { submissions } from 'utils/apiUrls';
import {
  QueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  QueryObserverOptions, UseQueryOptions,
} from '@tanstack/react-query';
import { ERequestStatus } from 'pages/HomePage/types';
import { TApiListResponse, TApiResponse } from 'utils/apiResponse.type';


export interface ISolution {
  id: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  request_status: ERequestStatus;
  error_details: string | null;
  question_id: string;
  question_str: string;
  repo_url: string;
  repo_type: string | 'GITHUB';
  s3_url: string;
  scores: string[];
  overall_score: number;
  positive_comments: string | null;
  negative_comments: string | null;
}

export interface ISolutionFileStructure {
  questionId: string;
  solutionId: string;
  evaluation_details: {
    directory_structure: {
      [projectName: string]: {
        [subDirectory: string]: {
          [fileName: string]: {
            original_file: string | null;
            eval_file: string | null;
            has_comments: boolean;
            criteria_scores: {
              [criterion: string]: number;
            };
          };
        };
      };
    };
    code_comments: {
      files: {
        file_path: string;
        improvements: {
          original_code: string;
          improved_code: string;
          start_line: number;
          end_line: number;
          reasoning: string;
          criterion: string;
        }[];
      }[];
    };
  };
}

export interface ISolutionTestResult {
    score: number;
    evaluation: string;
    strengths: string[];
    weaknesses: string[];
    test_results: string;
    raw_results: string;
    test_cases_code: TestCodeSnippet[];
    status: ERequestStatus,
    error_details: string,
    solution_id: string,
    id: string,
    created_at: string,
    updated_at: string,
    deleted_at: string | null,
}

export interface TestCodeSnippet {
    'Tip': string;
    'Code Snippet from Repository': string;
    [testName: string]: string;
}


export interface ICreateSolutionRequest {
  questionId: string;
  git_url: string;
  url_type: string;
}

export const userSolutionQueryKey = (questionId: string) => [
  'users',
  'solutions',
  questionId,
  'list',
];

export function useGetUserSolution(
  questionId: string,
  options?: QueryObserverOptions<TApiListResponse<ISolution>, Error>,
) {
  return useQuery<TApiListResponse<ISolution>, Error>({
    queryKey: userSolutionQueryKey(questionId),
    queryFn: () => http.get(submissions.latest(questionId)),
    refetchInterval: 2000,
    ...options,
  });
}

export const userSolutionListQueryKey = () => ['users', 'solutions', 'list'];

export function useGetUserSolutionsList(
  options?: QueryObserverOptions<TApiListResponse<ISolution>, Error>,
) {
  return useQuery<TApiListResponse<ISolution>, Error>({
    queryKey: userSolutionListQueryKey(),
    queryFn: () => http.get(submissions.list),
    refetchInterval: 2000,
    ...options,
  });
}


export function useCreateSolution(
  options?: UseMutationOptions<void, Error, ICreateSolutionRequest>,
) {
  return useMutation<void, Error, ICreateSolutionRequest>({
    mutationFn: (app) => {
      const { questionId, ...payload } = app;
      return http.post(submissions.create(questionId), payload);
    },
    ...options,
  });
}

export function useDeleteSolution(options?: UseMutationOptions<void, Error, string>) {
  return useMutation<void, Error, string>({
    mutationFn: (solutionId) => http.delete(submissions.delete(solutionId)),
    ...options,
  });
}

export default function useCheckRepoVisibility(
  repoUrl: string,
  options?: QueryOptions<string, Error>,
) {
  return useQuery<string, Error>({
    queryKey: ['repoVisibility', repoUrl],
    queryFn: () => http.checkRepoVisibility(repoUrl),
    staleTime: 0,
    ...options,
  });
}

export const userSolutionFileKey = (solutionId: string) => [solutionId, 'solution', 'user', 'files'];

export function useGetSolutionFiles(
  solutionId: string,
  options?: UseQueryOptions<TApiResponse<ISolutionFileStructure>, Error>,
) {
  return useQuery<TApiResponse<ISolutionFileStructure>, Error>(
    {
      queryKey: userSolutionFileKey(solutionId),
      queryFn: () => http.get(submissions.files(solutionId)),
      ...options,
    },
  );
}

export function useCreateTesting(
  questionId: string,
  solutionId: string,
  options?: UseMutationOptions<void, Error, void>,
) {
  return useMutation<void, Error, void>(
    {
      mutationFn: () => http.post(submissions.tests(questionId, solutionId), {}),
      ...options,
    },
  );
}


export const userTestListKey = (solutionId: string) => [solutionId, 'tests', 'user', 'list'];

export function useGetTestList(
  solutionId: string,
  options?: UseQueryOptions<TApiListResponse<ISolutionTestResult>, Error>,
) {
  return useQuery<TApiListResponse<ISolutionTestResult>, Error>(
    {
      queryKey: userTestListKey(solutionId),
      queryFn: () => http.get(submissions.testList(solutionId)),
      ...options,
    },
  );
}
