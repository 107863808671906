import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import githubService from 'services/githubService';
import { Logger } from 'utils/logger';
import Spinner from 'components/Common/Spinner';
import { connections } from 'utils/spaUrls';


export default function GithubCallback() {
  const navigate = useNavigate();
  const hasConnectedRef = useRef(false);

  useEffect(() => {
    const handleCallback = async () => {
      if (hasConnectedRef.current) return;

      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');

      if (!code) {
        Logger.error('No GitHub code provided');
        navigate(connections.list);
        return;
      }

      try {
        hasConnectedRef.current = true;
        await githubService.connect(code);
        navigate(connections.list);
      }
      catch (error) {
        Logger.error('Failed to connect GitHub:', error);
        navigate(connections.list);
      }
    };

    handleCallback();
  }, [navigate]);

  return <Spinner />;
}
