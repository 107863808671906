import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
  SxProps,
  Theme,
  IconButton,
  Button,
  DialogActions,
} from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import CodeIcon from '@mui/icons-material/Code';
import CloseIcon from '@mui/icons-material/Close';


type QuestionType = 'takehome' | 'leetcode' | null;

type TProps = {
    isOpen: boolean;
    onClose: () => void;
    onGenerate: (type: string) => void;
};

export default function QuestionGenerationModal({ isOpen, onClose, onGenerate }: TProps) {
  const [selectedType, setSelectedType] = useState<QuestionType>(null);

  const handleGenerate = () => {
    if (selectedType) {
      onGenerate(selectedType);
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth='lg'
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <Box sx={{ position: 'relative', p: 3 }}>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant='h4' sx={{ mb: 1, fontWeight: 'bold' }}>
          Let&apos;s start your Job Preparation
        </Typography>

        <Typography variant='subtitle1' color='text.secondary' sx={{ mb: 4 }}>
          Choose the type of practice questions you want to work on
        </Typography>

        <Typography variant='h5' sx={{ mb: 3, fontWeight: 'medium' }}>
          Practice Questions
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card
              sx={cardStyle(selectedType === 'takehome')}
              onClick={() => setSelectedType('takehome')}
            >
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <GitHubIcon sx={{ fontSize: 40, mb: 2, color: 'text.secondary' }} />
                  <Typography variant='h6' sx={{ mb: 1 }}>
                    GitHub Project Questions
                  </Typography>
                  <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>
                    Practice system design and project implementation questions
                    commonly asked in interviews
                  </Typography>
                  <Box component='ul' sx={{ pl: 2 }}>
                    <Typography component='li' variant='body1' color='text.secondary'>
                      Architecture and design patterns
                    </Typography>
                    <Typography component='li' variant='body1' color='text.secondary'>
                      Project structure and organization
                    </Typography>
                    <Typography component='li' variant='body1' color='text.secondary'>
                      Code quality and best practices
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              sx={cardStyle(selectedType === 'leetcode')}
              onClick={() => setSelectedType('leetcode')}
            >
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <CodeIcon sx={{ fontSize: 40, mb: 2, color: 'text.secondary' }} />
                  <Typography variant='h6' sx={{ mb: 1 }}>
                    LeetCode Style Questions
                  </Typography>
                  <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>
                    Practice algorithmic and data structure problems similar to LeetCode
                  </Typography>
                  <Box component='ul' sx={{ pl: 2 }}>
                    <Typography component='li' variant='body1' color='text.secondary'>
                      Algorithm challenges
                    </Typography>
                    <Typography component='li' variant='body1' color='text.secondary'>
                      Data structure problems
                    </Typography>
                    <Typography component='li' variant='body1' color='text.secondary'>
                      Coding optimization
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <DialogActions sx={{ p: 3, pt: 2 }}>
        <Button
          variant='outlined'
          onClick={onClose}
          size='large'
          sx={{ minWidth: 100 }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={handleGenerate}
          disabled={!selectedType}
          size='large'
          sx={{ minWidth: 100 }}
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const dialogPaperStyle: SxProps<Theme> = {
  width: 'auto',
  m: 2,
  maxHeight: '90vh',
};

const cardStyle = (isSelected: boolean): SxProps<Theme> => ({
  height: '100%',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  border: (theme) => (isSelected ? `2px solid ${theme.palette.primary.main}` : '1px solid rgba(0, 0, 0, 0.12)'),
  '&:hover': {
    boxShadow: (theme) => theme.shadows[8],
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
  },
});
