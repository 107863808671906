import { Box } from '@mui/material';
import React from 'react';
import ApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import theme from 'theme';


export default function ScoreCircle({ score }: { score: number }) {
  // Logic to determine score text (Good, Fair, Poor, Excellent)
  const scoreText = () => {
    if (score >= 0 && score < 20) {
      return 'Poor';
    }
    else if (score >= 20 && score < 50) {
      return 'Fair';
    }
    else if (score >= 50 && score < 80) {
      return 'Good';
    }
    else {
      return 'Excellent';
    }
  };

  const scoreColor = () => {
    if (score >= 0 && score < 20) {
      return theme.palette.error.light;
    }
    else if (score >= 20 && score < 50) {
      return theme.palette.warning.light;
    }
    else if (score >= 50 && score < 80) {
      return theme.palette.primary.light;
    }
    else {
      return theme.palette.primary.main;
    }
  };

  // Chart options configuration with total label showing the score text
  const chartOptions: ApexOptions = {
    chart: {
      type: 'donut',
      height: 250,
    },
    colors: [scoreColor(), theme.palette.grey['400']], // Customize colors as per your theme
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            total: {
              showAlways: true,
              label: '',
              fontSize: '22px',
              fontWeight: 'bold',
              color: '#000',
              formatter: () => scoreText(),
              show: true,
            },
          },
        },
      },
    },
    labels: [scoreText()],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
  };

  const chartSeries = [score, 100 - score];

  return (
    <Box
      sx={{
        position: 'relative',
        width: 300,
      }}
    >
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type='donut'
        height={250}
      />
    </Box>
  );
}
