import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import theme from 'theme';
import { useQuery } from '@tanstack/react-query';
import githubService, { GithubStatus, RequestStatus } from 'services/githubService';
import Spinner from 'components/Common/Spinner';
import ConnectionGithubCard from 'pages/Connections/components/ConnectionGithubCard';
import EmptyConnectionPage from 'pages/Connections/components/EmptyConnectionPage';
import GithubConnect from 'components/Github/GithubConnect';


export default function ConnectionList() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: statusResponse, isLoading: statusLoading } = useQuery({
    queryKey: ['github-status'],
    queryFn: githubService.getStatus,
    retry: 5,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  });

  const intervalIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    if (statusResponse?.data?.status === 'PROCESSING') {
      intervalIdRef.current = window.setInterval(() => {
        window.location.reload();
      }, 5000);
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [statusResponse?.data?.status]);

  if (!statusResponse || statusLoading || statusResponse.data.status === RequestStatus.PROCESSING) {
    return <Spinner />;
  }

  const githubStatusData: GithubStatus = statusResponse.data;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        container
        xs={12}
        alignItems='flex-end'
        justifyContent='space-between'
        sx={{ mb: 2 }}
      >
        <Typography variant={isMobile ? 'h5' : 'h4'}>Connections</Typography>
        {githubStatusData.isLinked ? (
          <Button
            size={isMobile ? 'small' : 'medium'}
            variant='contained'
            sx={{ bgcolor: 'dark.main', color: 'white' }}
          >
            Edit Github Connection
          </Button>
        ) : (
          <GithubConnect />
        )}
      </Grid>
      {githubStatusData.isLinked ? (
        <ConnectionGithubCard githubStatus={githubStatusData} />
      ) : (
        <EmptyConnectionPage />
      )}
    </Grid>
  );
}
