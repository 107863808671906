import {
  Button,
  Card, CardActions, CardContent, CardHeader,
  Divider, Grid, IconButton, List, ListItemIcon, Menu, MenuItem, Tooltip, Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Avatar from '@mui/material/Avatar';
import {
  IApplication,
  useCreateQuestions, useDeleteApplication,
  useGetApplicationQuestions,
  userApplicationListQueryKey,
} from 'pages/HomePage/queries';
import { positionParsed } from 'pages/HomePage/constants';
import {
  BusinessCenterRounded, Delete,
} from '@mui/icons-material';
import React, { useState } from 'react';
import stringToDate from 'components/Common/Date';
import { ApplicationDetails } from 'pages/HomePage/components/FlipCard';
import { useQueryClient } from '@tanstack/react-query';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import { Presence } from 'pages/HomePage/components/Presence';
import { useNavigate } from 'react-router-dom';
import SpinnerButton from 'components/Common/SpinnerButton';
import CustomChip from 'pages/HomePage/components/CustomChip';
import { trimText } from 'pages/HomePage/components/utils';
import QuestionGenerationModal from 'pages/Questions/components/QuestionGenerationModal';


type TProps = {
  application: IApplication;
}

export default function NewApplicationView({ application }: TProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [questionProcessing, setQuestionProcessing] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const createQuestionsHook = useCreateQuestions();
  const deleteApplicationHook = useDeleteApplication();
  const {
    data: questionData,
    isLoading,
  } = useGetApplicationQuestions(application.id);

  const handleQuestionClick = () => {
    if (questionData && questionData.data && questionData.data.length > 0 && !isLoading) {
      trackEvents(EEvents.CLICK, {
        feature: 'HomePage - Questions',
        type: 'Button',
        label: 'View Questions',
      });
      navigate(`/questions/list?company=${application.company.name}`);
    }
    else {
      trackEvents(EEvents.CLICK, {
        feature: 'HomePage - Questions',
        type: 'Button',
        label: 'Generate Questions',
      });
      setModalOpen(true);
    }
  };

  const handleQuestionGeneration = (type: string) => {
    createQuestionsHook.mutate({
      application_id: application.id,
      type,
    });
    setQuestionProcessing(true);
  };

  const handleViewApplication = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'HomePage - Application',
      type: 'Button',
      label: 'View Application',
    });
    navigate(`/application/${application.id}`);
  };

  const handleDelete = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'HomePage - Application',
      type: 'Button',
      label: 'Delete Application',
    });
    deleteApplicationHook.mutate(
      application.id,
      {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: userApplicationListQueryKey() }),
      },
    );
  };

  React.useEffect(() => {
    if (questionData && questionData.data?.length > 0) {
      setQuestionProcessing(false);
    }
  }, [questionData]);

  const menuText = () => {
    if (questionData && questionData?.data?.length > 0) {
      return 'View Questions';
    }
    else if (questionProcessing) {
      return 'Processing';
    }
    else {
      return 'Generate Questions';
    }
  };

  const status = () => {
    if (questionProcessing) {
      return 'process';
    }
    else if (questionData && questionData?.data?.length > 0) {
      return 'success';
    }
    else if (questionData && questionData?.error !== null) {
      return 'fail';
    }
    else {
      return 'saved';
    }
  };

  const content: Record<string, React.ReactNode> = {
    'Job Applied': stringToDate({ dateString: application.job_applied, formatStr: 'MMMM dd, yyyy' }),
    Skills: (
      <CustomChip skills={application.skills} renderNumber={1} />
    ),
    Questions: (
      <SpinnerButton
        isLoading={questionProcessing}
        color='primary'
        variant='outlined'
        size='small'
        endIcon={menuText() === 'View Questions' ? <ArrowOutwardIcon /> : null}
        onClick={handleQuestionClick}
      >
        {menuText()}
      </SpinnerButton>
    ),
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Grid key={application.id} item xs={12} md={6} lg={4} xl={3}>
      <Card style={{ width: '100%', height: '100%' }}>
        <CardHeader
          action={(
            <div>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <Delete fontSize='small' />
                  </ListItemIcon>
                  Delete Application
                </MenuItem>
              </Menu>
            </div>
              )}
          avatar={application?.company?.logo
            ? (
              <>
                <Avatar src={application.company.logo} />
                <Presence size='small' status={status()} />
              </>
            )
            : (
              <>
                <Avatar>
                  <BusinessCenterRounded />
                </Avatar>
                <Presence size='small' status={status()} />
              </>
            )}
          subheader={`Position: ${positionParsed.parse(application.role).title}`}
          title={(
            <Tooltip title={application.company.name} placement='top-start'>
              <Typography variant='h6'>{trimText(application.company.name)}</Typography>
            </Tooltip>
          )}
        />
        <CardContent sx={{ py: '8px' }}>
          <List disablePadding>
            {Object.entries(content).map(([label, value]) => (
              <ApplicationDetails label={label} value={value} />
            ))}
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='secondary' endIcon={<ArrowForwardOutlinedIcon />} size='small' onClick={handleViewApplication}>
            View Application
          </Button>
        </CardActions>
      </Card>
      {modalOpen && (
      <QuestionGenerationModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onGenerate={handleQuestionGeneration}
      />
      )}
    </Grid>
  );
}
