import React from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import EmptyStateSvg from 'assets/EmptyState.svg';


export default function EmptyJobs() {
  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' direction='column'>
      <Grid item xs={12}>
        <Box
          component='img'
          src={EmptyStateSvg}
          alt='No Job Listings'
          sx={{ height: '148px', width: '148px', marginBottom: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '605px', textAlign: 'center', margin: '0 auto' }}>
          <Typography variant='h5' gutterBottom>
            No Job Listings
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            No job listings found currently with the listed filter.
            Please try to change filters and try again. We are trying our best to fetch jobs.
            We have noted your request and will soon add jobs with these filters in mind.
            Please check it out tomorrow.
          </Typography>

        </Box>
      </Grid>
    </Grid>
  );
}
