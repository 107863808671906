import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { GitHub } from '@mui/icons-material';
import { ApplicationDetails } from 'pages/HomePage/components/FlipCard';
import React from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { GithubStatus } from 'services/githubService';


type TProps = {
    githubStatus: GithubStatus
}

interface DisplayEntry {
    label: string;
    value: string;
    color?: string;
    icon?: React.ElementType;
}


export default function ConnectionGithubCard({ githubStatus }: TProps) {
  // Create a formatted display object
  const displayEntries: DisplayEntry[] = [
    {
      label: 'Status',
      icon: githubStatus.isLinked ? PowerIcon : PowerOffIcon,
      value: githubStatus.isLinked ? 'Connected' : 'Not Connected',
      color: githubStatus.isLinked ? 'success.main' : 'error.main',
    },
    {
      label: 'Github Username',
      value: githubStatus.githubUsername || 'N/A',
    },
  ];

  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ borderRadius: 2 }}>
        <CardHeader
          title={(
            <Stack direction='row' spacing={2} alignItems='center'>
              <GitHub />
              <Typography variant='h6'>Github</Typography>
            </Stack>
                    )}
        />
        <CardContent>
          <List disablePadding>
            {displayEntries.map((entry) => (
              <ApplicationDetails
                key={entry.label}
                label={entry.label}
                value={(
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {entry.icon && (
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        bgcolor: entry.color || 'primary.main',
                      }}
                    >
                      {React.createElement(entry.icon, { fontSize: 'small' })}
                    </Avatar>
                    )}
                    <Typography color={entry.color || 'text.primary'}>
                      {entry.value}
                    </Typography>
                  </Box>
                                )}
              />
            ))}
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color='secondary'
            endIcon={<ArrowForwardOutlinedIcon />}
            size='small'
          >
            Edit Connection
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
