import {
  ArticleRounded, SvgIconComponent, TerminalRounded,
} from '@mui/icons-material';
import Anchor from '@mui/icons-material/Anchor';
import LogoutIcon from '@mui/icons-material/Logout';
import FeedbackIcon from '@mui/icons-material/Feedback';
import WorkIcon from '@mui/icons-material/Work';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import * as spaUrl from 'utils/spaUrls';


export type TNavItemModel = {
  name: string;
  Icon: SvgIconComponent;
  spaUrl: string;
  feature?: string;
  createLink?: string;
}

type TNavTreeModel = TNavItemModel[];

type TAllItems = {
  [key: string]: TNavItemModel;
};

export const allItems: TAllItems = {
  landingPage: {
    name: 'Landing Page',
    Icon: Anchor,
    spaUrl: spaUrl.homepage,
  },
  logout: {
    name: 'Logout',
    Icon: LogoutIcon,
    spaUrl: spaUrl.auth.logout,
  },
  applications: {
    name: 'Applications',
    Icon: ArticleRounded,
    spaUrl: spaUrl.homepage,
  },
  submissions: {
    name: 'Submissions',
    Icon: TerminalRounded,
    spaUrl: spaUrl.submissions,
  },
  connections: {
    name: 'Connections',
    Icon: SettingsInputComponentIcon,
    spaUrl: spaUrl.connections.list,
  },
  questions: {
    name: 'Questions',
    Icon: TerminalRounded,
    spaUrl: spaUrl.questions.list,
  },
  github: {
    name: 'GitHub',
    Icon: TerminalRounded,
    spaUrl: '/github',
  },
  jobs: {
    name: 'Job Listings',
    Icon: WorkIcon,
    spaUrl: spaUrl.jobs.list,
  },
  feedback: {
    name: 'Feedback',
    Icon: FeedbackIcon,
    spaUrl: 'https://codei.convas.io/',
  },
};

export const NavTree: TNavTreeModel = [
  allItems.jobs,
  allItems.applications,
  allItems.questions,
  allItems.connections,
];

export const UserMenuTree: TNavTreeModel = [
  allItems.feedback,
  allItems.logout,
];


const allTitles = Object.values(allItems).map((item) => item.name);

export type AllPageTitles = typeof allTitles[number]
