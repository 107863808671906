import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  SxProps,
  Theme,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import {
  Lock,
  Public,
  Upload as UploadIcon,
} from '@mui/icons-material';
import githubService, { GithubRepository } from 'services/githubService';
import SpinnerButton from 'components/Common/SpinnerButton';
import { EGitSource, ERequestStatus } from 'pages/HomePage/types';
import { IQuestion } from 'pages/HomePage/queries';
import useSolutionSubmission from 'pages/Submissions/useSolutionSubmission';
import { useQuery } from '@tanstack/react-query';


type Props = {
    onSelect: (repo: GithubRepository) => void;
    question: IQuestion;
    isLoading?: boolean;
    onSubmit: () => void;
    sx?: SxProps<Theme>;
    width?: string | number;
    reposLoading?: boolean; // Added to allow parent to pass in loading state
};

export default function RepositorySelect({
  onSelect,
  question,
  onSubmit,
  isLoading,
  sx,
  width = 450,
  reposLoading: externalReposLoading,
}: Props) {
  const {
    createSolutionHook,
  } = useSolutionSubmission({});

  const { data: reposResponse, isLoading: internalReposLoading } = useQuery({
    queryKey: ['github-repositories'],
    queryFn: githubService.getRepositories,
  });

  const reposLoading = externalReposLoading !== undefined
    ? externalReposLoading : internalReposLoading;

  const repositories: GithubRepository[] = reposResponse?.data || [];
  const [selectedRepo, setSelectedRepo] = React.useState<string>('');

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSelectedRepo(value);
    const repo = repositories.find((r) => r.full_name === value);
    if (repo) {
      onSelect(repo);
    }
  };

  const questionId = question.id;

  const onUpload = (url: string) => {
    if (questionId) {
      createSolutionHook.mutate({
        questionId,
        git_url: url,
        url_type: EGitSource.URL,
      });
      onSubmit();
    }
  };

  const renderMenuItem = (repo: GithubRepository) => (
    <MenuItem
      key={repo.id}
      value={repo.full_name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        py: 1,
      }}
    >
      <Box sx={{ fontWeight: 'bold' }}>{repo.name}</Box>
      <Box sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>
        {repo.full_name}
      </Box>
      {repo.description && (
        <Box
          sx={{
            fontSize: '0.875rem',
            color: 'text.secondary',
            mt: 0.5,
          }}
        >
          {repo.description}
        </Box>
      )}
      <Box
        sx={{
          fontSize: '0.875rem',
          color: 'text.secondary',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mt: 0.5,
        }}
      >
        <Box
          component='span'
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          {repo.is_private ? (
            <>
              <Lock fontSize='small' />
              <span>Private</span>
            </>
          ) : (
            <>
              <Public fontSize='small' />
              <span>Public</span>
            </>
          )}
        </Box>
        <Box
          component='a'
          href={repo.html_url}
          target='_blank'
          onClick={(e) => e.stopPropagation()}
          sx={{
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          View on GitHub
        </Box>
      </Box>
    </MenuItem>
  );

  const renderValue = (value: string) => {
    const repo = repositories.find((r) => r.full_name === value);
    return repo ? repo.name : '';
  };

  return (
    <FormControl
      sx={{
        width,
        ...sx,
      }}
    >
      <InputLabel id='select-repository-label'>Select Repository</InputLabel>
      <Select
        labelId='select-repository-label'
        label='Select Repository'
        id='select-repository'
        value={selectedRepo}
        onChange={handleChange}
        disabled={Boolean(
          question.submission
                    && question.submission.request_status !== ERequestStatus.FAIL,
        )}
        renderValue={renderValue}
        endAdornment={
        selectedRepo ? (
          <InputAdornment position='end'>
            <SpinnerButton
              startIcon={<UploadIcon />}
              variant='contained'
              size='small'
              isLoading={isLoading}
              disabled={Boolean(
                question.submission
                        && question.submission.request_status !== ERequestStatus.FAIL,
              )}
              onClick={() => onUpload(repositories.find((r) => r.full_name === selectedRepo)?.html_url || '')}
              sx={{
                mr: 2,
              }}
            >
              Upload
            </SpinnerButton>
          </InputAdornment>
        ) : null
      }
      >
        {reposLoading && (
        <MenuItem
          value=''
          disabled
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            py: 1.5,
            fontStyle: 'italic',
            color: 'text.secondary',
          }}
        >
          <CircularProgress size={16} />
          <span>Fetching repositories...</span>
        </MenuItem>
        )}

        {repositories.map(renderMenuItem)}
      </Select>
    </FormControl>
  );
}
