import { useEffect, useRef, useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // Swap for other themes if needed
import { Box, Button } from '@mui/material';


type CodeBlockProps = {
    code: string;
    sx?: object; // Allow styling from parent
};

export default function CodeBlock({ code, sx = {} }: CodeBlockProps) {
  const codeRef = useRef<HTMLElement | null>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.innerText = code;
      hljs.highlightElement(codeRef.current);
    }
  }, [code]);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: '#1e1e1e !important', // 🔥 Force black background
        color: '#ffffff !important', // 🔥 Force white text
        borderRadius: '5px',
        padding: '10px',
        overflow: 'auto',
        fontFamily: "'Fira Code', monospace",
        fontSize: '14px',
        lineHeight: 1.5,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        '& pre': {
          margin: 0,
          backgroundColor: 'inherit',
          color: 'inherit',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        },
        '& code': {
          backgroundColor: 'inherit',
          color: 'inherit',
          fontSize: '14px',
        },
        ...sx, // Allow external overrides
      }}
    >
      <Button
        onClick={handleCopy}
        size='small'
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'rgba(255, 255, 255, 0.1)',
          color: 'white',
          borderRadius: '4px',
          fontSize: '12px',
          padding: '3px 8px',
          '&:hover': { background: 'rgba(255, 255, 255, 0.3)' },
        }}
      >
        {copied ? 'Copied!' : 'Copy'}
      </Button>
      <pre>
        <code ref={codeRef} />
      </pre>
    </Box>
  );
}
