import {
  Button,
  Card, CardActions, CardContent, CardHeader,
  Divider, Grid, List, Tooltip, Typography,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Avatar from '@mui/material/Avatar';
import {
  userApplicationListQueryKey,
} from 'pages/HomePage/queries';
import { positionParsed } from 'pages/HomePage/constants';
import {
  BusinessCenterRounded,
} from '@mui/icons-material';
import React, { useState } from 'react';
import stringToDate from 'components/Common/Date';
import { ApplicationDetails } from 'pages/HomePage/components/FlipCard';
import { useQueryClient } from '@tanstack/react-query';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import { Presence } from 'pages/HomePage/components/Presence';
import { useNavigate } from 'react-router-dom';
import CustomChip from 'pages/HomePage/components/CustomChip';
import { trimText } from 'pages/HomePage/components/utils';
import TrackJobModal from 'pages/JobBoard/components/TrackJobModal';
import { IJobDetails, userJobListAppliedQueryKey, useTrackJobListing } from 'pages/JobBoard/queries';


type TProps = {
    jobListing: IJobDetails;
    appliedFlag: boolean | undefined;
}

export default function JobListingView({ jobListing, appliedFlag }: TProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [jobTracking, setJobTracking] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const trackJobsHook = useTrackJobListing(jobListing.id);

  const handleTrackJob = (response: boolean) => {
    if (response) {
      trackJobsHook.mutate();
      queryClient.invalidateQueries({ queryKey: userApplicationListQueryKey() });
      queryClient.invalidateQueries({ queryKey: userJobListAppliedQueryKey() });
    }
    setJobTracking(response);
  };

  const handleViewJobListing = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'Job - JobListing',
      type: 'Button',
      label: 'View Job Listing',
    });
    navigate(`/jobs/${jobListing.id}`);
  };

  const jobUrlRedirect = () => {
    window.open(jobListing.jd_url, '_blank');
    if (appliedFlag) {
      setModalOpen(false);
    }
    else {
      setModalOpen(true);
    }
  };

  React.useEffect(() => {
    if (appliedFlag) {
      setJobTracking(true);
    }
  }, [appliedFlag]);

  const status = () => {
    if (jobTracking) {
      return 'success';
    }
    else {
      return 'saved';
    }
  };

  const content: Record<string, React.ReactNode> = {
    'Job Posted': stringToDate({ dateString: jobListing.job_post_timestamp, formatStr: 'MMMM dd, yyyy' }),
    Skills: (
      <CustomChip skills={jobListing.skills} renderNumber={1} />
    ),
    Questions: (
      <Button
        color='primary'
        variant='outlined'
        size='small'
        endIcon={<ArrowOutwardIcon />}
        onClick={jobUrlRedirect}
      >
        Apply to Job
      </Button>
    ),
  };

  return (
    <Grid key={jobListing.id} item xs={12} md={6} lg={4} xl={3}>
      <Card style={{ width: '100%', height: '100%' }}>
        <CardHeader
          // TODO: Add bookmark logic
          // action={(
          //   <IconButton onClick={handleBookmarkJob}>
          //     <MoreVertIcon />
          //   </IconButton>
          // )}
          avatar={jobListing?.logo
            ? (
              <>
                <Avatar src={jobListing.logo} />
                <Presence size='small' status={status()} />
              </>
            )
            : (
              <>
                <Avatar>
                  <BusinessCenterRounded />
                </Avatar>
                <Presence size='small' status={status()} />
              </>
            )}
          subheader={`Position: ${positionParsed.parse(jobListing.role).title}`}
          title={(
            <Tooltip title={jobListing.companyName} placement='top-start'>
              <Typography variant='h6'>{trimText(jobListing.companyName)}</Typography>
            </Tooltip>
                    )}
        />
        <CardContent sx={{ py: '8px' }}>
          <List disablePadding>
            {Object.entries(content).map(([label, value]) => (
              <ApplicationDetails label={label} value={value} />
            ))}
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='secondary' endIcon={<ArrowForwardOutlinedIcon />} size='small' onClick={handleViewJobListing}>
            View Job Listing
          </Button>
        </CardActions>
      </Card>
      {modalOpen && (
        <TrackJobModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onTrack={handleTrackJob}
        />
      )}
    </Grid>
  );
}
