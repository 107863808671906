import { github } from 'utils/apiUrls';
import http from 'utils/http';
import { Logger } from 'utils/logger';


export type GithubRepository = {
  id: string;
  name: string;
  full_name: string;
  html_url: string;
  is_private: boolean;
  description?: string;
};

export type GithubStatus = {
  isLinked: boolean;
  githubUsername: string;
  status: RequestStatus
};

export enum RequestStatus {
  PROCESSING = 'PROCESSING',
}

export type GithubError = {
  message: string;
};

const githubService = {
  connect: async (code: string) => {
    try {
      return await http.post(`${github.connect}?code=${code}`, {});
    }
    catch (error) {
      Logger.error('Error connecting GitHub account:', error);
      throw error;
    }
  },

  getRepositories: async () => {
    try {
      return await http.get(github.repositories);
    }
    catch (error) {
      Logger.error('Error fetching repositories:', error);
      throw error;
    }
  },

  getStatus: async () => {
    try {
      return await http.get(github.status);
    }
    catch (error) {
      Logger.error('Error checking GitHub status:', error);
      throw error;
    }
  },
};

export default githubService;
